import { PostDataType } from "data/types";
import React, { FC } from "react";
import Badge from "components/Badge/Badge";

export interface CategoryBadgeProps {
  className?: string;
  itemClass?: string;
  categories?: string | any;
}

const CategoryBadge: FC<CategoryBadgeProps> = ({
  className = "flex flex-wrap space-x-2",
  itemClass,
  categories,
}) => {
  return (
    <div
      className={`nc-CategoryBadgeList ${className}`}
      data-nc-id="CategoryBadgeList"
    >
       <Badge
          className={itemClass}
          key={0}
          name={categories}
          href=""
          color="blue"
        />
    </div>
  );
};

export default CategoryBadge;
