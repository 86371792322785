import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import NcImage from "components/NcImage/NcImage";
import Layout from "app/(others)/layout";
import { getBlogById } from "services/blogService";
import { PostDataType } from "data/types";
import BlogHeader from "./BlogHeader";
import SingleContentBlog from "./SingleContent";

const PageBlogDetail = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState<PostDataType | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchBlog = async () => {
      if (!id) {
        setError("Invalid blog ID");
        return;
      }

      try {
        const data = await getBlogById(id);
        setBlog(data);
      } catch (error) {
        setError("Failed to fetch blog.");
      }
    };

    fetchBlog();
  }, [id]);

  if (error) {
    return (
      <div className="container mx-auto text-center text-red-500">{error}</div>
    );
  }

  if (!blog) {
    return <div className="container mx-auto text-center">Loading...</div>;
  }

  return (
    <Layout>
      <Helmet>
        <title>{`${blog.title} | Inspired by the Cross`}</title>
        <meta name="description" content={blog.desc} />
        
        {/* Open Graph / Facebook */}
        <meta property="og:title" content={blog.title} />
        <meta property="og:description" content={blog.desc} />
        <meta property="og:image" content={blog.featuredImage || blog.mobileImage} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="article" />
        
        {/* Twitter */}
        <meta name="twitter:card" content={blog.featuredImage}/>
        <meta name="twitter:title" content={blog.title} />
        <meta name="twitter:description" content={blog.desc} />
        <meta name="twitter:image" content={blog.featuredImage || blog.mobileImage} />

        {/* Facebook */}
        <meta property="og:title" content={blog.title} />
        <meta property="og:description" content={blog.desc} />
        <meta property="og:image" content={blog.featuredImage || blog.mobileImage} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="article" />

        {/* Instagram */}
        <meta property="insta:title" content={blog.title} />
        <meta property="insta:description" content={blog.desc} />
        <meta property="insta:image" content={blog.featuredImage || blog.mobileImage} />
        <meta property="insta:url" content={window.location.href} />
        <meta property="insta:type" content="article" />
        
      </Helmet>

      <div className={`pt-8 lg:pt-16`}>
        {/* SINGLE HEADER */}
        <header className="container rounded-xl">
          <div className="max-w-screen-md mx-auto">
            <BlogHeader post={blog} />
          </div>
        </header>

        <div className="container max-w-screen-md mx-auto space-y-10">
          <div className="prose dark:prose-dark">
            <SingleContentBlog post={blog} />
            <img
                src={blog.mobileImage}
                alt={blog.title}
                className="w-full h-full object-cover rounded-lg shadow-lg"
              />
          </div>
        </div>

   

      
      </div>
    </Layout>
  );
};

export default PageBlogDetail;  