import React from "react";
import logoImg from "images/logo.png";
import logoLightImg from "images/logo-light.png";
import LogoSvg from "./LogoSvg";
import Link from "components/Link";

export interface LogoProps {
  img?: string;
  imgLight?: string;
}

const Logo: React.FC<LogoProps> = ({
  img = logoImg,
  imgLight = logoLightImg,
}) => {
  return (
    <Link
      href="/"
      className="ttnc-logo inline-block text-primary-6000 flex-shrink-0 flex items-center space-x-2"
    >
      {/* Logo image */}
      <img src={img} alt="Logo" className="h-10" />
      {/* Text next to the logo */}
      <span className="text-lg font-semibold">Inspired by the Cross</span>
    </Link>
  );
};

export default Logo;