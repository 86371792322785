import React, { FC } from "react";
import PostCardLikeAndComment from "components/PostCardLikeAndComment/PostCardLikeAndComment";
import { PostDataType } from "data/types";

export interface BlogMetaActionProps {
  className?: string;
  post?: PostDataType;
}

const BlogMetaAction: FC<BlogMetaActionProps> = ({ className = "", post }) => {
  return (
    <div className={`nc-BlogMetaAction ${className}`}>
      <div className="flex flex-row space-x-2.5 items-center">
        {post && (
          <PostCardLikeAndComment
            blogId={post._id}
            viewCount={post.viewsCount}
            itemClass="px-4 h-9 text-sm"
            hiddenCommentOnMobile
            useOnSinglePage
            className="!space-x-2.5"
            likeCount={post.likesCount}
          />
        )}
        <div className="px-1">
          <div className="border-l border-neutral-200 dark:border-neutral-700 h-6" />
        </div>
      </div>
    </div>
  );
};

export default BlogMetaAction;