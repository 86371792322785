import React, { FC, useEffect, useState } from "react";
import CategoryBadgeList from "components/CategoryBadgeList/CategoryBadgeList";
import PostMeta2 from "components/PostMeta2/PostMeta2";

import { DEMO_CATEGORIES } from "data/taxonomies";
import { BlogPost, PostDataType } from "data/types";
import axios from "axios";
import SingleTitle from "app/(singles)/SingleTitle";
import SingleMetaAction2 from "app/(singles)/SingleMetaAction2";
import BlogMeta from "./BlogMeta";
import BlogMetaAction from "./BlogMetaAction";
import CategoryBadge from "app/Home/CategoryBadge";

export interface BlogHeaderProps {
  hiddenDesc?: boolean;
  titleMainClass?: string;
  className?: string;
  post?: PostDataType 
}

const BlogHeader: FC<BlogHeaderProps> = ({
  titleMainClass,
  hiddenDesc = false,
  className = "", 
  post
}) => {

  return (
    console.log("SingleHeader.tsx", post),
    <>
      <div className={`nc-SingleHeader ${className}`}>
        <div className="space-y-5">
          {post ? (
            <>
              <img
                src={post.featuredImage}
                alt={post.title}
                className="w-full h-full object-cover rounded-lg shadow-lg"
              />
              <CategoryBadge  categories={post.categories[0]} />
              <SingleTitle mainClass={titleMainClass} title={post.title} />
              {!hiddenDesc && (
                <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
                  {post.desc}
                </span>
              )}

              
              <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
              <div className="flex flex-col sm:flex-row justify-between sm:items-end space-y-5 sm:space-y-0 sm:space-x-5">
                <BlogMeta
                  meta={post}
                  size="large"
                  className="leading-none flex-shrink-0"
                  hiddenCategories
                  avatarRounded="rounded-full shadow-inner"
                />
                <BlogMetaAction post={post}/>
              </div>
            </>
          ) : (
            <div>Error loading post or post not found</div>
          )}
        </div>
      </div>
    </>
  );
};

export default BlogHeader;