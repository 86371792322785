import Image from "components/Image";
import { PostDataType } from "data/types";
import React, { FC } from "react";
import DOMPurify from "dompurify";

export interface SingleContentBlogProps {
  hiddenDesc?: boolean;
  titleMainClass?: string;
  className?: string;
  post?: PostDataType;
}

const SingleContentBlog: FC<SingleContentBlogProps> = ({
  hiddenDesc = false,
  className = "",
  post,
}) => {
  return (
    <>
        <div
            className={`nc-SingleContentBlog ${className}`}
            dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(post?.content || ""),
            }}
        />
      
    </>
  );
};

export default SingleContentBlog;
