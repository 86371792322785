import { FC } from "react";
import Link from "components/Link";
import { CustomLink } from "data/types";

interface CategoriesPaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const CategoriesPagination: FC<CategoriesPaginationProps> = ({ currentPage, totalPages, onPageChange }) => {
  const renderItem = (page: number, index: number) => {
    const isActive = currentPage === page;
    const className = isActive
      ? "inline-flex w-11 h-11 items-center justify-center rounded-full bg-primary-6000 text-white"
      : "inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700";
    
    return (
      <span
        key={index}
        onClick={() => onPageChange(page)}
        className={`${className} cursor-pointer`}
      >
        {page}
      </span>
    );
  };

  const pageLinks = [];
  for (let i = 1; i <= totalPages; i++) {
    pageLinks.push(renderItem(i, i - 1));
  }

  return (
    <nav className="inline-flex space-x-1 text-base font-medium">
      {pageLinks}
    </nav>
  );
};

export default CategoriesPagination;