import React, { useEffect, useState } from "react";
import { getBlogs, getTaxonomies } from "services/blogService";
import ButtonPrimary from "components/Button/ButtonPrimary";
import ArchiveFilterListBox from "components/ArchiveFilterListBox/ArchiveFilterListBox";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import Card11 from "components/Card11/Card11";
import { PostDataType, TaxonomyType } from "data/types";
import CategoriesPagination from "./CategoriesPagination";

const Categories = () => {
  const [blogs, setBlogs] = useState<PostDataType[]>([]);
  const [taxonomies, setTaxonomies] = useState<TaxonomyType[]>([]);
  const [error, setError] = useState<string | null>(null);

  const [currentPage, setCurrentPage] = useState<number>(1); // Track the current page
  const limit = 10; // Number of items per page
  const [totalPages, setTotalPages] = useState<number>(1); // Total number of pages

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const category = urlParams.get("category") || "";

    const fetchBlogs = async () => {
      try {
        const data = await getBlogs(currentPage, limit, category);
        setBlogs(data.blogs);
        setTotalPages(data.pagination.totalPages); // Set total pages from the response
      } catch (error) {
        setError("Failed to fetch blogs.");
      }
    };

    const fetchTaxonomies = async () => {
      try {
        const data = await getTaxonomies();
        setTaxonomies(data);
      } catch (error) {
        console.error("Failed to fetch taxonomies.");
      }
    };

    fetchBlogs();
    fetchTaxonomies();
  }, [currentPage, limit]);

  const FILTERS = [
    { name: "Most Recent" },
    { name: "Curated by Admin" },
    { name: "Most Appreciated" },
    { name: "Most Discussed" },
    { name: "Most Viewed" },
  ];

  const handlePageChange = (page: number) => {
    setCurrentPage(page); // Update the current page when user clicks on pagination
  };

  return (
    <div className="nc-PageArchive">
      <div className="container pt-10 pb-16 lg:pb-28 lg:pt-20 space-y-16 lg:space-y-28">
        {/* Filters */}
        <div className="flex flex-col sm:justify-between sm:flex-row">
          <div className="block my-4 border-b w-full border-neutral-300 dark:border-neutral-500 sm:hidden"></div>
          <div className="flex justify-end">
            <ArchiveFilterListBox lists={FILTERS} />
          </div>
        </div>

        {/* Blog Posts */}
        <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8 mt-8 lg:mt-10">
          {error ? (
            <p>{error}</p>
          ) : Array.isArray(blogs) && blogs.length > 0 ? (
            blogs.map((post) => <Card11 key={post.id} post={post} />)
          ) : (
            <p>No blogs found.</p>
          )}
        </div>

        {/* Pagination */}
        <div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
          <CategoriesPagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
        </div>
      </div>

    </div>
  );
};

export default Categories;