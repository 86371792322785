import ButtonPrimary from "components/Button/ButtonPrimary";
import React, { FC, ReactNode } from "react";
import Image from "../Image";

export interface SectionHeroProps {
  className?: string;
  rightImg: string;
  heading: ReactNode;
  subHeading: string;
  btnText: string;
}

const SectionHero: FC<SectionHeroProps> = ({
  className = "",
  rightImg,
  heading,
  subHeading,
  btnText,
}) => {
  return (
    <div className={`nc-SectionHero relative ${className}`}>
      <div className="flex flex-col lg:flex-row space-y-14 lg:space-y-0 lg:space-x-10 items-center relative text-center lg:text-left">
        <div className="w-screen max-w-full xl:max-w-lg space-y-5 lg:space-y-7">
          <h2 className="text-3xl !leading-tight font-semibold text-neutral-900 md:text-4xl xl:text-5xl dark:text-neutral-100">
            {heading}
          </h2>
          <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">
            Welcome to Inspired By The Cross, where faith, family, and
            inspiration come together to create a space filled with love and
            hope.
          </span>

          <p>
            I am Bistro Kaspar, a devoted follower of Christ, passionate
            blogger, and loving husband to my amazing wife, Glinda Ray.
            Together, we are blessed with two wonderful children who bring joy
            and meaning to our lives every single day.
          </p>

          <p>
            Our journey is rooted in a shared commitment to faith and spreading
            the Word of God. From heartfelt reflections on scripture to life’s
            daily lessons, we seek to share messages that resonate, uplift, and
            encourage others in their walk with Christ. With each post, our aim
            is to touch hearts, ignite hope, and strengthen the bonds of faith
            within our community.
          </p>

          <p>
            Our family’s journey is not without its own trials and triumphs, but
            through it all, we lean on our faith and draw strength from one
            another. Inspired By The Cross is not just a blog; it is a testament
            to the love, grace, and endless mercy we experience in our daily
            lives.
          </p>

          <p>
            We invite you to join us, connect with us, and share in the stories
            that reflect our devotion to a life centered on Christ. Whether you
            come seeking comfort, inspiration, or a community rooted in
            Christian values, know that you are welcomed here with open arms.
          </p>
          {!!btnText && <ButtonPrimary href="/">{btnText}</ButtonPrimary>}
        </div>
      </div>
    </div>
  );
};

export default SectionHero;
