import axios from 'axios';

const API_BASE_URL = 'https://inspiredbythecross.com/api';


export const getBlogs = async (page: number, limit: number, category?:string) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/blogs?page=${page}&limit=${limit}&category=${category}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// like blog id 
export const likeBlog = async (blogId: string) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/blogs/${blogId}/like`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// Get blog by id
export const getBlogById = async (blogId: string) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/blogs/${blogId}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// get Taxonomies
export const getTaxonomies = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/taxonomies`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};