import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSliderPosts from "components/Sections/SectionSliderPosts";
import SectionMagazine2 from "components/Sections/SectionMagazine2";
import HomeSectionLargeSlider from "./HomeSectionLargeSlider";

// Services
import { getBlogs, getTaxonomies } from "services/blogService";

// Types
import { PostDataType, TaxonomyType } from "../../data/types";
import SectionMagazine3 from "components/Sections/SectionMagazine3";

const LandingHomePage = () => {
  const [blogs, setBlogs] = useState<PostDataType[]>([]);
  const [taxonomies, setTaxonomies] = useState<TaxonomyType[]>([]);
  const [error, setError] = useState<string | null>(null);

  const page = 1;
  const limit = 10;

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch blogs
        const blogData = await getBlogs(page, limit, "");
        setBlogs(blogData.blogs);

        // Fetch taxonomies
        const taxonomyData = await getTaxonomies();
        setTaxonomies(taxonomyData);
      } catch (error) {
        setError("Failed to fetch data.");
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title>Inspired by the Cross | Daily Bible Verses & Christian Inspiration</title>
        <meta name="description" content="Explore Inspired by the Cross for daily Bible verses, Christian inspiration, and messages that strengthen faith and uplift spirits." />
        <meta property="og:title" content="Inspired by the Cross | Daily Bible Verses & Christian Inspiration" />
        <meta property="og:description" content="Get daily encouragement with Bible verses and inspirational Christian messages from Inspired by the Cross." />
        <meta property="og:image" content="https://i0.wp.com/inspiredbythecross.com/wp-content/uploads/2024/09/cropped-Inspired-by-Sacrifice-Living-with-Purpose.png?w=353&ssl=1" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />

        {/* Facebook */}
        <meta property="article:author" content="Inspired by the Cross" />
        <meta property="article:publisher" content="Inspired by the Cross" />

      

      </Helmet>

      <div className="nc-PageHome relative container">
        <HomeSectionLargeSlider
          className="pt-10 pb-16 md:py-16 lg:pb-28 lg:pt-20"
          posts={blogs
            .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
            .slice(0, 3)} // Limit to the first 3 posts
        />

        <div className="relative py-16">
          <BackgroundSection />

          <SectionMagazine2
            className="py-16 lg:py-24"
             heading="Recent Articles"
            posts={blogs}
          />
   
          <SectionSliderPosts
            postCardName="card11"
            heading="More design articles"
            subHeading="Over 1118 articles"
            posts={blogs.filter((_, i) => i > 3 && i < 25)}
          />
        </div>

        <SectionSliderNewCategories
          className="py-16 lg:py-28"
          heading="Categories"
          subHeading="Over 1118 articles"
          categories={taxonomies.slice(0, 10)}
          categoryCardType="card4"
        />
      </div>
    </>
  );
};

export default LandingHomePage;