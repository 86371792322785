import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Page } from "./types";
import Footer from "components/Footer/Footer";
import MusicPlayer from "components/MusicPlayer/MusicPlayer";
import PageSearch from "app/(search)/search/page";
import PageSearchV2 from "app/(search)/search-2/page";
import PageAbout from "app/about/page";
import PageContact from "app/(others)/contact/page";
import Page404 from "app/not-found";
import SiteHeader from "app/SiteHeader";
import PageBlogDetail from "app/blog-detail/page";
import LandingHomePage from "app/Home/Home";
import Categories from "app/Categories/Categories";

export const pages: Page[] = [
  { path: "/", component: LandingHomePage },
  { path: "/search", component: PageSearch },
  { path: "/search-2", component: PageSearchV2 },
  { path: "/about", component: PageAbout },
  { path: "/contact", component: PageContact },
  { path: "/blog/:id", component: PageBlogDetail },
  { path: "/categories", component: Categories },
];

const MyRoutes = () => {
  return (
    <BrowserRouter>
      <SiteHeader />

      <Routes>
        {pages.map(({ component: Component, path }, index) => (
          <Route key={index} path={path} element={<Component />} />
        ))}
        <Route path="*" element={<Page404 />} /> {/* Catch-all 404 route */}
      </Routes>

      <Footer />
      <MusicPlayer />
    </BrowserRouter>
  );
};

export default MyRoutes;